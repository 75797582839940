<template>
<v-col cols="12" class="home-content">
  
  <v-row>
    <v-col cols="11" sm="12" md="12" lg="12" class="mx-auto pb-0 header-section text-center mt-0 mb-5">
      
      <v-icon class="d-none d-md-flex" style="font-size:6em; margin-bottom:-70px;" color="primary">mdi-feather</v-icon>
      <v-icon class="d-md-none" style="font-size:4em; margin-bottom:10px;" color="primary">mdi-feather</v-icon>
      <h1 class="font-main pb-1 pt-5 mb-5 pb-5 yellow--text text--darken-2" @click="initData">
        Le code des pirates
      </h1>
    </v-col>

    <v-col cols="11" sm="8" md="6" lg="5" class="mx-auto pt-0 header-section text-center mt-5 mb-5">
        <v-card-text class="font-papyrus">
          Le "code des pirates" n'est pas un ensemble de règles formelles. Il doit plutôt être considéré une sorte de guide,
          pour devenir le meilleur des pirates...
        </v-card-text>
    </v-col>
  </v-row>

  <v-row style="">
    <v-col cols="12" sm="11" md="11" lg="8" class="mx-auto text-left px-0" style="max-width: 1000px;">
        <template v-for="(post, p) in posts">
          <v-card :key="p" v-if="post.categories[0].name == 'code des pirates'"
                      class="font-main card-code px-4 pt-md-2"
                      color="transparent" elevation="0">
            <v-card-title class="title-code font-papyrus primary--text">
              <small>{{ post.position }}<v-icon large color="primary">mdi-feather</v-icon> {{ post.title }}</small>
            </v-card-title>
              <v-card-text class="px-5 font-papyrus" v-html="nl2br(post.text)"></v-card-text>
          </v-card>
        </template>


      <br><br><br>
        <div class="text-center">
          <v-btn class="game-menu-btn dashed font-main pt-2 mb-5" 
                style="font-size:33px!important;height:80px!important;" 
                to="/register" v-if="!$store.state.auth.isLogin"
                elevation="0">
            Devenir un pirate
          </v-btn>
          <br>

          <v-btn class="game-menu-btn dashed font-main pt-2 mb-5" 
                style="font-size:33px!important;height:80px!important;" 
                to="/book" v-if="!$store.state.auth.isLogin"
                elevation="0">
            Lire le journal de bords
          </v-btn>
        </div>

    </v-col>

  </v-row>

  <br><br><br><br><br><br>

</v-col>
</template>

<style>
  .card-code .v-card__text{
    font-size:17px !important;
  }
</style>

<script>
  import core from '../../plugins/core.js'

  import '@/assets/css/blog.css'
  

  export default {
    name: "App",
    components: {
      
    },
    data: function () {
        return {
            posts: [],
            postToShow: false,
            showPostDialog: false,
        };
    },
    mounted: function(){
      this.$store.dispatch('app/incStat', '/book')
      this.initData()
      this.$root.$on('closeDialogPost', () => { this.closeDialogPost() })
    },
    methods: {
        async initData(){
           console.log('diffSecs');
          await this.$store.dispatch('app/fetchEntities', { entityType: "blogPost", query: { public: true }, sort: 'position' })
          this.posts = this.$store.state.app.data.blogPost

          console.log("Posts", this.posts)
        },

        nl2br: function(str, is_xhtml){
          return core.nl2br(str, is_xhtml)
        },

        baseUrl(){ return core.baseUrl() },
    },
    computed:{
      
    },
    watch: {
      
    }
  };
</script>

